import React from "react";

const AboutCareer = () => {
  return (
    <div data-aos="fade-up" data-aos-duration={600}>
      <div data-aos="fade-up" data-aos-duration={600}>
        <div className="row justify-content-center p-6">
          <div className="text-xl-justify pr-lg-10 pr-xl-0 col-lg-12 col-sm-12 col-md-12 col-xs-12">
            <h3 className="font-size-8 mb-0 mt-10">
              We are looking for an experienced Angular Developer to lead our
              front-end development and create high-performance, user-friendly
              interfaces. If you are passionate about UI/UX and love working
              with the latest front-end technologies, this role is for you!
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCareer;
