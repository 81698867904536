import React from "react";
const Details = () => {
  return (
    <div className="container" data-aos="fade-up" data-aos-duration={600}>
      <div className="">
        <h2 className="font-size-9 mb-4 pb-3">What You’ll Do</h2>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Lead front-end development using <b>Angular (2.0 and later).</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Develop responsive, mobile-first applications that offer a
            seamless user experience.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Collaborate with UI/UX designers and back-end developers.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Work with <b>RESTful APIs</b> to ensure smooth data exchange.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Optimize performance, scalability, and maintainability of
            applications.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Manage code repositories and version control efficiently.
          </li>
        </ul>

        <h2 className="font-size-9 mb-4 p-3">What We’re Looking For</h2>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ 3+ years of experience in{" "}
            <b>Angular, JavaScript, Node.js, HTML, and CSS.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Strong knowledge of{" "}
            <b>RESTful APIs, asynchronous programming, and API integrations.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Experience with
            <b>mobile-first, cross-browser compatible UI development.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Strong
            <b>communication and project management skills.</b>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Details;
