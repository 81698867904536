import React from "react";

const JoinCS = ({ title }) => {
  return (
    <div
      className="p-10 m-5 mt-7 mb-7"
      data-aos="fade-up"
      data-aos-duration={600}
    >
      <h3 className="font-size-9 mb-4 pb-3"> Why Cloudstack?</h3>
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
          🚀 <b>Impact-Driven Work</b> – Build products that help businesses
          grow faster.
        </li>
        <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
          🌱 <b>Growth-Focused Culture</b> – We prioritize learning,
          skill-building, and innovation.
        </li>
        <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
          💡 <b>Collaborative Environment</b> – Work with like-minded, talented
          professionals.
        </li>
        <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
          🌎 <b>Flexible Work Mode</b> – Choose remote or hybrid work options
          that fit your lifestyle.
        </li>
        <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
          🎯 <b>Opportunities to Lead</b> – Take ownership, innovate, and shape
          the future of SaaS.
        </li>       
      </ul>
    </div>
  );
};

export default JoinCS;
